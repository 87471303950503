const local = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
const remote = !local

export const environment = {
    production: true,
    local,
    remote,
    package: {
        version: require('../../package.json').version,
        name: require('../../package.json').name,
    },
    google: {
        api: {
            clientId: '346758244346-tfsr2rlf7tl4vsbkj6f2n8mukdo2lgnv.apps.googleusercontent.com',
            clientSecret: 'VJrWmRUBXDUEseF5P5mdNvNu',
        },
    },
    sentry: {
        dsn: {
            'wl-brandfirm': 'https://ffccd1446268413dba61e5977809b1aa@o253450.ingest.sentry.io/5542295',
        },
    },
}
